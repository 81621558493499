<template>
  <div>
    <b-breadcrumb>
      <b-breadcrumb-item :to="`/${home}`">
        <b-icon
          icon="house-fill"
          scale="1.25"
          shift-v="1.25"
          aria-hidden="true"
        ></b-icon>
      </b-breadcrumb-item>
      <b-breadcrumb-item :to="{ name: 'user' }">User</b-breadcrumb-item>
      <b-breadcrumb-item active>Add User</b-breadcrumb-item> </b-breadcrumb
    ><br />

    <b-card title="Add User">
      <div class="backButton">
        <b-button variant="primary" :to="{ name: 'user' }">Back</b-button>
      </div>
      <b-row>
        <b-col cols="12" class="mt-1">
          <b-row>
            <b-col cols="6">
              <span>Firstname</span>
              <b-form-group label-for="form-firstname">
                <b-form-input
                  id="form-firstname"
                  v-model="form.props.firstName"
                >
                </b-form-input>
              </b-form-group>

              <b-form-group label-for="form-role">
                <span>Role <span style="color:red">(*)</span></span>
                <v-select
                  placeholder="----- Select Role -----"
                  label="label"
                  v-model="form.roleRef"
                  :options="roles"
                  :clearable="false"
                />
              </b-form-group>

              <b-form-group label-for="form-emailname">
                <span>Email </span>
                <b-form-input id="form-emailname" v-model="form.email">
                </b-form-input>
              </b-form-group>

              <b-form-group label-for="form-identificationNumber">
                <span>Identification Number </span>
                <b-form-input
                  id="form-identificationNumber"
                  v-model="form.props.identificationNumber"
                  type="number"
                >
                </b-form-input>
              </b-form-group>

              <b-form-group label="Address" label-for="form-address">
                <!-- <b-form-input
                                id="form-address"
                                v-model="form.props.address"
                            >
                            </b-form-input> -->
                <b-form-textarea
                  id="form-address"
                  v-model="form.props.address"
                  placeholder="Enter address..."
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Last Name" label-for="form-lastname">
                <b-form-input id="form-lastname" v-model="form.props.lastName">
                </b-form-input>
              </b-form-group>

              <b-form-group label="Phone" label-for="form-phone">
                <b-form-input
                  id="form-phone"
                  v-model="form.props.phone"
                  type="number"
                >
                </b-form-input>
              </b-form-group>
                <b-form-group label-for="form-username">
                  <span>Username <span style="color:red">(*)</span></span>
                  <b-row>
                    <b-col >
                  <b-form-input
                    name="Username"
                    rules="required"
                    placeholder="username"
                    v-model="form.username"
                  >
                  </b-form-input>

                    </b-col>
                
              
                <b-button class="mr-1 w-5" variant="success" @click="availUsername()">
                  check username
                </b-button>
                
                </b-row>

              </b-form-group>
              <b-form-group>
                <span>Password <span style="color:red">(*)</span></span>
                <validation-provider
                  name="Password"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      v-model="form.password"
                      :type="passwordFieldType"
                      @keydown="validateInputCharacter"
                      class="form-control-merge"
                      placeholder="Password"
                      :state="errors.length > 0 ? false : null"
                      @input="checkPasswordStrength"
                    ></b-form-input>
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <span
                  v-if="form.password.length !== 7 && form.password !== ''"
                  style="color:red"
                  >Your password must be 7 characters.</span
                >
              </b-form-group>
              <b-form-group>
                <b-col cols="12">
                  <b-row>
                    <b-col cols="3" style="margin-bottom:15px">
                      Password Strength
                    </b-col>
                    <b-col cols="9">
                      <div
                        style="display:flex"
                        v-if="strengthResult[0] === 'Weak'"
                      >
                        <div class="bar-red">
                          Weak
                        </div>

                        <div class="bar-off"></div>

                        <div class="bar-off"></div>
                      </div>

                      <div
                        style="display:flex"
                        v-if="strengthResult[0] === 'Medium'"
                      >
                        <div class="bar-orange"></div>

                        <div class="bar-orange">
                          Medium
                        </div>

                        <div class="bar-off"></div>
                      </div>

                      <div
                        style="display:flex"
                        v-if="strengthResult[0] === 'Strong'"
                      >
                        <div class="bar-green"></div>

                        <div class="bar-green"></div>

                        <div class="bar-green">
                          Strong
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
                <b-row>
                  <b-col cols="12">
                    <b-row>
                      <b-col cols="3">
                        <div
                          class="character-on"
                          v-if="strengthResult[1].includes('Lower Case')"
                        >
                          Lower Case
                        </div>
                        <div class="character-off" v-else>Lower Case</div>
                      </b-col>

                      <b-col cols="3">
                        <div
                          class="character-on"
                          v-if="strengthResult[1].includes('Upper Case')"
                        >
                          Upper Case
                        </div>
                        <div class="character-off" v-else>Upper Case</div>
                      </b-col>

                      <b-col cols="3">
                        <div
                          class="character-on"
                          v-if="strengthResult[1].includes('Number')"
                        >
                          Number
                        </div>
                        <div class="character-off" v-else>Number</div>
                      </b-col>

                      <!-- <b-col cols="3">
                        <div
                          class="character-on"
                          v-if="strengthResult[1].includes('Symbol')"
                        >
                          Symbol
                        </div>
                        <div class="character-off" v-else>Symbol</div>
                      </b-col> -->
                    </b-row>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-row>
            <b-col cols="4"></b-col>
            <b-col cols="4"></b-col>
            <b-col cols="4">
              <b-button
                class="float-right"
                size="md"
                variant="success"
                @click="save"
              >
                Save
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { dateFormat, userAccess, viewAccess } from "@/utils/utils.js";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import { required, email } from "@validations";
import axios from "@/axios";

export default {
  components: {
    vSelect,
    "validation-observer": ValidationObserver,
    "validation-provider": ValidationProvider,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      strength: "",
      character: "",

      title: "",
      form: {
        email: "",
        username: "",
        password: "",
        role: "",
        roleRef: "",
        props: {
          firstName: "",
          lastName: "",
          address: "",
          props: "",
          phone: "",
          identificationNumber: "",
          app: "",
        },
      },
    };
  },
  methods: {
    save() {
      this.form.props.app = this.$session.get("phibase-app");
      this.$store
        .dispatch("user/addUser", {
          ...this.form,
        })
        .then(() => {
          this.$bvToast.toast("Successfully Add User", {
            title: "Success",
            variant: "success",
            solid: true,
          });
          // this.clearFields();
          setTimeout(() => {
            this.$router.push({ name: "user" });
          }, 1000);
        })
        .catch((err) => {
          console.log({ err });
          if (
            this.form.username == undefined ||
            this.form.password == undefined
          ) {
            for (let i = 0; i < err.response.data.errors.length; i++) {
              this.$bvToast.toast(err.response.data.errors[i].message, {
                title: "Failed",
                variant: "danger",
                solid: true,
              });
            }
          } else {
            for (let i = 0; i < err.response.data.errors.length; i++) {
              this.$bvToast.toast(err.response.data.errors[i].message, {
                title: "Failed",
                variant: "danger",
                solid: true,
              });
            }
          }
        });
    },
    validateInputCharacter(e) {
      if (!/^[a-zA-Z0-9]*$/.test(e.key)) {
        e.preventDefault();
      }
    },
    checkPasswordStrength() {
      if (/^[a-zA-Z0-9]*$/.test(this.form.password)) {
      axios
        .post("/common/password-strength/", {
          password: this.form.password,
        })
        .then((res) => {
          let entropy = res.data.entropy;
          this.strength = res.data.strength;
          this.character = res.data.characters;
        })
        .catch((err) => console.log({ err }));
      }
    },
    clearFields() {
      this.form = {
        email: "",
        username:"",
        password: "",
        role: "",
        roleRef: "",
        props: {
          firstName: "",
          lastName: "",
          app: "",
        },
      };
    },
    availUsername() {
      var getUsers = this.$store.getters["user/getUser"]

        const usernames = getUsers.map((users) => users.username)
        if(usernames.includes(this.form.username) || this.form.username.includes(" ") || this.form.username.length < 5) {
          this.$bvToast.toast("This Username can not be used", {
                title: "This Username is unavailable",
                autoHideDelay: 3000,
                variant: "danger",
                solid: true,
              });
        }else if(!usernames.includes(this.form.username)){
          this.$bvToast.toast("you can use this username", {
                title: "This Username available",
                autoHideDelay: 3000,
                variant: "success",
                solid: true,
              });
        }
    }
  },
  computed: {
    roles() {
      var getRoles = this.$store.getters["role/getRole"]
        ? this.$store.getters["role/getRole"]
            .filter((doc) => {
              return doc.name !== "Super Admin" && doc.level !== "Super Admin";
            })
            .map((el) => {
              return {
                ...el,
                label: `${el.name} - ${el.level}`,
              };
            })
        : [];
      return getRoles;
    },
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    strengthResult() {
      return [this.strength, this.character];
    },
    home() {
      return viewAccess()[0];
    },
    rows() {
      return this.users.length;
    },

  },
  created() {
    document.title = "User | Phibase - Common";
  },
  mounted() {
    this.$store.dispatch("user/fetchUser").catch((err) => console.log(err));
    this.$store.dispatch("role/fetchRole").catch((err) => console.log(err));
  },
};
</script>

<style>
.character-off {
  text-align: center;
  background: #f6f6f6;
  padding: 9px 0;
  color: gray;
  border-radius: 10px;
}

.character-on {
  text-align: center;
  background: #0892d0;
  padding: 9px 0;
  color: white;
  border-radius: 10px;
}

.bar-off {
  margin: 3px;
  text-align: center;
  background: #f6f6f6;
  padding: 3px 0;
  color: white;
  border-radius: 3px;
  width: 33%;
}

.bar-red {
  margin: 3px;
  text-align: center;
  background: #ea5455;
  padding: 3px 0;
  color: white;
  border-radius: 3px;
  width: 33%;
}

.bar-orange {
  margin: 3px;
  text-align: center;
  background: #ff9f43;
  padding: 3px 0;
  color: white;
  border-radius: 3px;
  width: 33%;
}

.bar-green {
  margin: 3px;
  text-align: center;
  background: #28c76f;
  padding: 3px 0;
  color: white;
  border-radius: 3px;
  width: 33%;
}

@media (min-width: 761px) {
  .show-on-mobile {
    display: none !important;
  }
}
@keyframes spinner {
  0% {
    transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  100% {
    transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}
.spin::before {
  animation: 1.5s linear infinite spinner;
  animation-play-state: inherit;
  border: solid 5px #cfd0d1;
  border-bottom-color: #0077b3;
  border-radius: 50%;
  content: "";
  height: 20px;
  width: 20px;
  position: absolute;
  margin-top: 20px;
  transform: translate3d(-50%, -50%, 0);
  will-change: transform;
}
</style>
